
<app-header></app-header>

<main *ngIf="mostrar">
    <div class="relative">
        <div class="banner bg_infografia bg-cover bg-no-repeat h-[330px] grid items-center">   
        </div>
        <img class="absolute top-[30px] left-[43px]" src="../../../../assets/icons/arrow-left.png" alt="flecha">
        <div class="absolute title text-center text-white">
            <!-- <h6 class="text-[16px] leading-[110%] mb-[20px] block">Programa de Especializaci&oacute;n</h6> -->
            <h1 class="text-[44px] leading-[110%]">Programa de Especializaci&oacute;n</h1>
        </div>
    </div>
    <div class="cabezera">
        <div class="titulo">Certif&iacute;cate y capac&iacute;tate con tus becas</div>
        <!-- <a routerLink="/usuario/programa-profesional-modulos" style="background: #FF0000; color: white; cursor: pointer;" 
            class="boton text-center text-white text-2xl sm:text-3xl md:text-base w-full
             rounded-[10px] py-3 md:border-2 md:bg-white md:py-2 md:w-[240px] block font-medium">
             Matric&uacute;late aqu&iacute;
             <i class="fa-solid fa-chevron-right"></i>
        </a> -->
        
    </div>

    <div class="arrow-down"><i class="fa-solid fa-chevron-down"></i><i class="fa-solid fa-chevron-down"></i></div>
    <!-- <div class="arrow-down"><img style="width: 85%; margin-left: 3px; margin-top: 6px;" src="../../../assets/icons/doble_flecha_abajo.png"></div> -->

        <div class="cuerpo relative">
            <div class="linea" data-aos="fade-up" data-aos-anchor-placement="top-bottom"></div>
            <div class="questions">
            <div class="question1" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                <div class="imagen-container">
                    <img src="../../../assets/img/info1.png" alt="info1"/>
                </div>   
                <div class="contenido">
                    <h3>¿En qué consiste esta beca?</h3>
                    <p>
                        Es un plan de estudios digital con sesiones sincrónicas (en vivo), desarrollado en alianza con el Instituto Capeco.
                        El programa consta de 100 horas y está dividido en 6 módulos.
                    </p>
                </div>
            </div>
            <div class="question2" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                <div class="contenido" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    <div class="imagen-container">       
                        <img src="../../../assets/img/info2.png" alt="info2"/>
                    </div>
                    <div class="pregunta-container">   
                        <h3 class="pregunta">¿Cómo puedo postular a la beca?</h3>
                    </div> 
                </div> 
                <div class="pasos-container">
                <div class="pasos">
                    <div class="paso" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <img src="../../../assets/svg/forma1.svg" alt="forma1"/>
                        <div class="info">
                            <h3>Paso 1</h3>
                            <p>Completar el formulario con todos tus datos. Podrás encontrarlo al finalizar esta explicación</p>
                        </div>
                    </div>
                    <div class="paso" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <img src="../../../assets/svg/forma2.svg" alt="forma2"/>
                        <div class="info">
                            <h3>Paso 2</h3>
                            <p>
                                Unacem Cantera se comunicará contigo vía teléfono para validar tu información y el interés en esta beca de estudios,
                                por eso es muy importante que coloques tus datos reales en el.
                            </p>
                        </div>
                    </div>
                    <div class="paso" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <img src="../../../assets/svg/forma3.svg" alt=""/>               
                        <div class="info"> 
                            <h3>Paso 3</h3>                    
                            <p>
                                Se realizará un sorteo entre todos los postulantes calificados para destinar las becas y se le comunicará en el
                                periodo especificado si fuiste seleccionado.
                            </p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="question3" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <div class="imagen-container"> 
                    <img src="../../../assets/img/info3.png" alt="info3"/>
                </div>
                <div class="contenido">
                    <h3>¿En qué consiste esta beca?</h3>
                    <p>
                        Es un plan de estudios digital con sesiones sincrónicas (en vivo), desarrollado en alianza con el Instituto Capeco. 
                        El programa consta de 100 horas y está dividido en 6 módulos.
                    </p>
                </div>
            </div>
            </div>
        </div>

        <div class="arrow-end-container" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            <div class="arrow-end">
                <i class="fa-solid fa-chevron-down"></i>
                <i class="fa-solid fa-chevron-down"></i>
            </div>
        </div>
        
        <div class="importante" data-aos="fade-up">
            <h1 class="titulo" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">Importante</h1>
            <div class="bloque-1" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <i class="fa-solid fa-angles-right"></i>
                <p>
                    Una vez sea aprobada tu postulación, recuerda que deberás ingresar a 
                    un curso introductorio obligatorio. Los alumnos que no podrán permanecer en la beca.
                </p>
            </div>
            <div class="bloque-2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <i class="fa-solid fa-angles-right"></i>
                <p>
                    Para seguir los cursos es indispensable contar con una computadora (o una laptop), 
                    conexión estable a internet y el tiempo necesario para culminar las sesiones dentro del tiempo establecido.
                </p>
                
            </div>
            <div class="bloque-3" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <i class="fa-solid fa-angles-right"></i>
                <p>
                    Durante las capacitaciones tendrás el soporte técnico necesario para poder llevar todos los cursos, por lo que
                    es importante mantenerte en contacto con el equipo de seguimiento.
                </p>
            </div>
            <!-- <div class="btn-matricula">
                <a (click)="openMatricula()" style="background: #FF0000; color: white; cursor: pointer;" 
                    class="boton text-center text-white text-2xl sm:text-3xl md:text-base w-full
                    rounded-[10px] py-3 border-2 md:bg-white md:w-[240px] block font-medium">
                    {{tituloBoton}}
                    <i class="fa-solid fa-chevron-right"></i>
                </a>
            </div> -->

            <!-- update 8/1/2024 -->
            <!-- <div class="btn-matricula" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
                <a (click)="openMatricula()" style="background: #FF0000; color: white; cursor: pointer;" 
                    class="boton text-center text-white text-2xl sm:text-3xl md:text-base w-full
                    rounded-[10px] py-3 border-2 md:bg-white md:w-[240px] block font-medium">
                    Matricúlate aquí
                    <i class="fa-solid fa-chevron-right"></i>
                </a>
            </div> -->
            <!-- fin update 8/1/2024 -->

            <!-- <div class="botones">
                <div class="btn-matricula">
                    <a (click)="openMatricula()" style="background: #FF0000; color: white; cursor: pointer;" 
                        class="boton text-center text-white text-2xl sm:text-3xl md:text-base w-full
                        rounded-[10px] py-3 border-2 md:bg-white md:w-[240px] block font-medium">
                        Matricúlate aquí
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </div>
                <div class="btn-ingresa">
                    <a (click)="openMatricula()" style="background: #FF0000; color: white; cursor: pointer;" 
                        class="boton text-center text-white text-2xl sm:text-3xl md:text-base w-full
                        rounded-[10px] py-3 border-2 md:bg-white md:w-[240px] block font-medium">
                        Ingresa alumno
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </div>
            </div> -->
        </div>
</main>

<app-footer></app-footer>

<div *ngIf="modalRegistro == true">
    <app-matricula></app-matricula>
</div>